import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../../../../img/logo.png";
import classes from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { boot } from "../../../../utils";

const Header = (props) => {
  const { showPricing = true } = props;

  return (
    <section
      className={`${classes.header} ${
        props.dark ? `dark ${classes.dark}` : ""
      }`}
    >
      <Navbar collapseOnSelect expand="lg" className="p-0">
        <div className={classes.wrapper}>
          <Navbar.Brand href="/">
            {/* <div>
              <Row>
                <Col xs="3" sm="3" md="3" lg="3" xl="3">
                  <img
                    src={page_1_icon}
                    style={{width:"40px", height:"auto", margin:"6px"}}
                  ></img>
                </Col>
                <Col xs="9" sm="9" md="9" lg="9" xl="9">
                  <div style={{fontWeight:"900", letterSpacing:"0.5px"}} className={classes.logoText}>engage</div>
                  <div  className={classes.logoText}>message</div>
                </Col>
              </Row>
            </div> */}
            <img src={logo} style={{ width: "140px", height: "auto" }}></img>
          </Navbar.Brand>
          {/* <div className={classes.search}>
                        <Search
                            onSearch={value => console.log(value)}
                            style={{ width: 150 }}
                        />
                    </div> */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="custom-toggler"
          />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {showPricing && (
              <Nav.Link className={classes.link}>
                <NavLink to="/pricing" activeClassName="link-active">
                  <span
                    style={{
                      fontWeight: "400",
                      fontFamily: '"Inter", "Arial", sans-serif',
                    }}
                  >
                    Pricing
                  </span>
                </NavLink>
              </Nav.Link>
            )}
            <Nav.Link
              className={classes.link}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <span
                onClick={boot}
                style={{
                  color: "black",
                  fontWeight: "400",
                  fontFamily: '"Inter", "Arial", sans-serif',
                }}
              >
                Chat with us
              </span>
            </Nav.Link>
          </Nav>
          <hr className={classes.hr} />
          {/* <div className={classes.hiddenElements}>
                            <Form className="form">
                                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                            </Form>
                            <Socials/>
                        </div> */}
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
};

export default Header;
