import React from "react";
import { LinkedIn, Twitter, Google } from "../../../../components/Icons/Icons";
import SocialsLinks from "../../../../components/Socials/Socials";
import page_2_icon_1 from "../../../../img/page_2_icon_1.png";
import page_2_icon_2 from "../../../../img/page_2_icon_2.png";
import page_2_icon_3 from "../../../../img/page_2_icon_3.png";
import page_2_icon_4 from "../../../../img/page_2_icon_4.png";
import page_2_icon_5 from "../../../../img/page_2_icon_5.png";
import page_2_icon_6 from "../../../../img/page_2_icon_6.png";
import Oval from "../../../../components/Frame/Frame";
import { Container, Col, Row } from "reactstrap";
import classes from "./Socials.module.css";
const iconText1 = "Social Media Marketing";
const iconText2 = "Email Marketing";
const iconText3 = "Beautiful Templates";
const iconText4 = "SMS Marketing";
const iconText5 = "Built-in Automations";
const iconText6 = "Customer Segments";

const Socials = (props) => {
  return (
    <div className={classes.socialsText}>
      <Row
        className={classes.normalFontFamily}
        justify="center"
        align="top"
        style={{ fontSize: "12px" }}
      >
        <Col lg="4" md="4" className={`${classes.iconPadding}`}>
          <Row justify="center" align="top">
            <Col lg="6" md="6" className={`mx-auto ${classes.saperator}`}>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <img
                    src={page_2_icon_6}
                    alt=""
                    style={{ width: "54px", height: "auto" }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <div className={classes.socialsText}>
                    <div style={{ padding: 0 }}>Social Media</div>
                    <div style={{ padding: 0 }}>Marketing</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" className={`mx-auto ${classes.saperator}`}>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <img
                    src={page_2_icon_5}
                    alt=""
                    style={{ width: "54px", height: "auto" }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <div className={classes.socialsText}>
                    <div style={{ padding: 0 }}>Email</div>
                    <div style={{ padding: 0 }}>Marketing</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="4" className={`${classes.iconPadding}`}>
          <Row justify="center" align="top">
            <Col lg="6" md="6" className={`mx-auto ${classes.saperator}`}>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <img
                    src={page_2_icon_1}
                    alt=""
                    style={{ width: "54px", height: "auto" }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <div className={classes.socialsText}>
                    <div style={{ padding: 0 }}>Beautiful</div>
                    <div style={{ padding: 0 }}>Templates</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" className={`mx-auto ${classes.saperator}`}>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <img
                    src={page_2_icon_2}
                    alt=""
                    style={{ width: "54px", height: "auto" }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <div className={classes.socialsText}>
                    <div style={{ padding: 0 }}>SMS</div>
                    <div style={{ padding: 0 }}>Marketing</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="4" className={`${classes.iconPadding}`}>
          <Row justify="center" align="top">
            <Col lg="6" md="6" className={`mx-auto ${classes.saperator}`}>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <img
                    src={page_2_icon_3}
                    alt=""
                    style={{ width: "54px", height: "auto" }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <div className={classes.socialsText}>
                    <div style={{ padding: 0 }}>Built-in</div>
                    <div style={{ padding: 0 }}>Automations</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" className={`mx-auto ${classes.saperator}`}>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <img
                    src={page_2_icon_4}
                    alt=""
                    style={{ width: "54px", height: "auto" }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="top">
                <Col lg="12" md="12" sm="12" xs="12" className={`mx-auto`}>
                  <div className={classes.socialsText}>
                    <div style={{ padding: 0 }}>Customer</div>
                    <div style={{ padding: 0 }}>Segments</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Socials;
