import React, { useEffect, useState } from "react";

import HeroLayoutsType1th from "./layouts/HeroLayouts/HeroLayoutsType1th/HeroLayoutsType1th";
import HeroLayoutsType2th from "./layouts/HeroLayouts/HeroLayoutsType2th/HeroLayoutsType2th";
import HeroLayoutsType2hth from "./layouts/HeroLayouts/HeroLayoutsType2hth/HeroLayoutsType2hth";
import HeroLayoutsType2ith from "./layouts/HeroLayouts/HeroLayoutsType2ith/HeroLayoutsType2ith";
import HeroLayoutsType3th from "./layouts/HeroLayouts/HeroLayoutsType3th/HeroLayoutsType3th";
import HeroLayoutsType4th from "./layouts/HeroLayouts/HeroLayoutsType4th/HeroLayoutsType4th";
import HeroLayoutsType45th from "./layouts/HeroLayouts/HeroLayoutsType45th/HeroLayoutsType5th";
import HeroLayoutsType5th from "./layouts/HeroLayouts/HeroLayoutsType5th/HeroLayoutsType5th";
import HeroLayoutsType6th from "./layouts/HeroLayouts/HeroLayoutsType6th/HeroLayoutsType6th";
import HeroLayoutsType7th from "./layouts/HeroLayouts/HeroLayoutsType7th/HeroLayoutsType7th";

import { HashRouter as Router, Route } from "react-router-dom";
import Pricing from "./layouts/Pricing";
import axios from "axios";

const Home = () => {
  const [showPricing, setShowPricing] = useState(false);

  useEffect(() => {
    const querySettings = async () => {
      const { data: settings } = await axios.get(
        "https://api.engagemessage.com/api/settings",
        {
          params: { platform: "web" },
          headers: {
            "x-em-app-id": "com.engagemessage.web.em",
          },
        }
      );

      setShowPricing(settings.show_pricing || false);
    };

    querySettings();
  }, []);

  return (
    <div>
      <HeroLayoutsType1th showPricing={showPricing} />
      <HeroLayoutsType2th />
      <HeroLayoutsType2hth />
      <HeroLayoutsType2ith />
      <HeroLayoutsType3th />
      <HeroLayoutsType4th />
      <HeroLayoutsType45th />
      <HeroLayoutsType6th />
      <HeroLayoutsType5th />
      <HeroLayoutsType7th showPricing={showPricing} />
    </div>
  );
};

function App() {
  useEffect(() => {
    window.Intercom("boot", {
      app_id: window.APP_ID,
    });
  }, []);

  return (
    <Router>
      <Route path="/" exact component={Home}></Route>
      <Route path="/pricing" exact component={Pricing}></Route>
    </Router>
  );
}

export default App;
