import { useEffect, useState } from "react";
import axios from "axios";

// 大部分代码都是从付费项目中拷贝过来的，所以个别地方可能不太合适，但是整体功能是可以的

// 从固定地址获取 plan 数据
const EMAIL_PLAN_URL =
  "https://api.engagemessage.com/api/subscriptions/plans?plan_type=1";
const SMS_PLAN_URL =
  "https://api.engagemessage.com/api/subscriptions/plans?plan_type=2";

// 根据给定的条件，从 plans 中找到对应的 plan
const findPlan = (contactsCount, plans, interval) => {
  const _count = parseInt(contactsCount, 10);

  contactsCount = isNaN(_count) || _count === 0 ? 1 : _count;
  interval = interval === "Yearly" ? 12 : 1;

  const plan = plans.find((plan) => {
    return (
      contactsCount <= plan.contacts_capacity &&
      plan.billing_interval === interval
    );
  });

  if (plan) {
    return plan;
  } else {
    return plans[plans.length - 1];
  }
};

export const makeComboPlan = (emailPlan, smsPlan) => {
  return {
    ...emailPlan,
    price: emailPlan.price + smsPlan.price,
    price_per_month: emailPlan.price_per_month + smsPlan.price_per_month,
    sms_contacts_capacity: smsPlan.contacts_capacity,
    sms_credit_capacity: smsPlan.sms_credit_capacity,
    name: PlanName(emailPlan, smsPlan),
    isCombo: true,
    emaiPlanID: emailPlan.id,
    smsPlanID: smsPlan.id,
  };
};

export const PlanName = (eamilPlan, smsPlan) => {
  if (isPaidPlan(eamilPlan) && isPaidPlan(smsPlan)) {
    return (
      eamilPlan.name.replace(/\(Monthly\)|\(Yearly\)/, "") + "& " + smsPlan.name
    );
  } else if (isPaidPlan(eamilPlan)) {
    return eamilPlan.name;
  } else if (isPaidPlan(smsPlan)) {
    return smsPlan.name;
  } else {
    return "FREE Plan";
  }
};

export function isPaidPlan(plan) {
  return plan.price !== 0;
}

const usePricing = () => {
  const [state, setState] = useState({
    emailContactCount: "1000", // 从输入框中获取的值，未转换为数字
    smsContactCount: "750",
    interval: "Yearly",
  });

  const [emailPlans, setEmailPlans] = useState();
  const [smsPlans, setSMSPlans] = useState();

  const [comboPlan, setComboPlan] = useState();
  const [emailPlan, setEmailPlan] = useState();
  const [smsPlan, setSMSPlan] = useState();

  useEffect(() => {
    const fetchPlans = async () => {
      const { data: emailPlans } = await axios.get(EMAIL_PLAN_URL);
      const { data: smsPlans } = await axios.get(SMS_PLAN_URL);

      setEmailPlans(emailPlans);
      setSMSPlans(smsPlans);
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    if (emailPlans && smsPlans) {
      const { emailContactCount, smsContactCount, interval } = state;

      const emailPlan = findPlan(emailContactCount, emailPlans, interval);
      const smsPlan = findPlan(smsContactCount, smsPlans, interval);

      setEmailPlan(emailPlan);
      setSMSPlan(smsPlan);
      setComboPlan(makeComboPlan(emailPlan, smsPlan));
    }
  }, [state, emailPlans, smsPlans]);

  return {
    state,
    setState,
    comboPlan,
    emailPlan,
    smsPlan,
  };
};

export default usePricing;
