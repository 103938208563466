import React from "react";

import { Container, Col, Row } from "reactstrap";
import Header from "./Header/Header";
import Slider from "./Slider/Slider";
import Slide from "./Slide/Slide";
import TextButton from "../../../components/Text-Button/Text-Button";
import Button from "../../../components/Button/Button";
import Oval from "../../../components/Frame/Frame";
import {
  Flash,
  Google,
  LinkedIn,
  Twitter
} from "../../../components/Icons/Icons";
import Socials from "../../../components/Socials/Socials";
import placeholder from "../../../img/IphoneXS.png";
import placeholderDark from "../../../img/IphoneXS-dark.png";
import page_3_logo from "../../../img/page_3_logo.png";
import page_3_right from "../../../img/page_3_right.png";
import classes from "./HeroLayoutsType3th.module.css";
import { Fade } from "react-awesome-reveal";

const title = "Design emails people love to read";
const description =
  "Let your email campaign shine through our aesthetic email templates. Customize your email and send every campaign that  truly converts to sales.";
const logoText = "Email Templates";

function HeroLayoutsTypeSecond(props) {
  return (
    <React.Fragment>
      <section
        className={`${classes.hero} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col className={classes.slider}>
                <Row>
                  <Col
                    lg={{ size: 5 }}
                    md={{ size: 5 }}
                    sm={5}
                    className={`left-column d-flex flex-column ${classes.xsLeft}`}
                  >
                    <div className={classes.imgTop}>
                      <img
                        src={page_3_logo}
                        alt=""
                        style={{ width: "auto", height: "22px" }}
                      />
                      <span className={classes.logoText}>{logoText}</span>
                    </div>
                    <h1
                      className={`text-left ${classes.topNone} ${classes.normalFontFamily} ${classes.title}`}
                    >
                      {title}
                    </h1>
                    <p
                      className={`normal-text description text-left ${classes.normalFontFamily} ${classes.description}`}
                    >
                      {description}
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 6 , offset: 1 }}
                    md={{ size: 6 , offset: 1 }}
                    sm={6}
                    className="right-column"
                  >
                    <img
                      src={page_3_right}
                      style={{ height: "auto", width: "100%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default HeroLayoutsTypeSecond;
